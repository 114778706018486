import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { videoApi } from '../../../api/videoApi.js';
import { NavBar } from '../../app/navBar.js';
import moment from 'moment';
import './videoSelect.css';

function VideoSelect() {
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const pageSize = 25; // Number of videos per page
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const response = await videoApi.getVideos(page, pageSize);
                setVideos(response.data.data);
            } catch (err) {
                setError(err.message || "An error occurred.");
            }
        })();
    }, [page]); // Re-fetch when `page` changes

    const nextPage = () => setPage(prev => prev + 1);
    const prevPage = () => setPage(prev => (prev > 1 ? prev - 1 : 1));

    return (
        <div>
            <NavBar />
            <h2>Please select a video</h2>

            {error ? (
                <p>{error}</p>
            ) : (
                <div className="videos-container">
                    <ul>
                        {Array.isArray(videos) && videos.map(video => (
                            <li key={video.id}>
                                <div
                                    className="video-thumbnail"
                                    onClick={() => navigate(`/videos/${video.id}`)}
                                >
                                    <img
                                        src={video.thumbnailBytes || '/placeholder.png'}
                                        alt={`Thumbnail for ${video.title}`}
                                        onError={(e) => {
                                            e.target.onerror = null; // Prevent infinite loop
                                            e.target.src = '/placeholder.png';
                                        }}
                                    />
                                    <div className="video-info">
                                        <h4>{video.title}</h4>
                                        <p>
                                            {video.takenOn?.date
                                                ? moment(video.takenOn.date).format('MM/DD/YYYY')
                                                : 'Unknown date'}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>

                    {/* Pagination Controls */}
                    <div className="pagination">
                        <button onClick={prevPage} disabled={page === 1}>
                            Previous Page
                        </button>
                        <span>Page {page}</span>
                        <button onClick={nextPage}>
                            Next Page
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export { VideoSelect };
